<template>
  <v-data-table
    hide-default-header
    class="server-table"
    :footer-props="{
      'items-per-page-options': [25, 50, 100, 500, -1],
      'items-per-page-text': 'Rows per page',
    }"
    :items-per-page="50"
    :headers="localColumns"
    :items="filteredItems"
    :search="search"
    :loading="isLoading"
    :dense="dense"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    @click:row="rowClick"
  >
    <template #top>
      <div class="d-flex align-stretch">
        <slot name="top"></slot>
        <v-text-field v-model="search" class="mt-4" :append-icon="mdiMagnify" label="Search" dense />
        <v-btn class="mt-2" icon title="Refresh data" @click="refresh">
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
        <ColumnDialog
          :headers.sync="localColumns"
          :all-headers="allHeaders"
          :base-headers="baseHeaders"
        />
        <FiltersDialog
          v-if="!hideFilters"
          :headers.sync="localColumns"
          :all-headers="allHeaders"
          :metadata="metadata"
          :filters.sync="localFilters"
        />
        <slot name="buttons"></slot>
        <DownloadData :entity-set="entitySet" />
        <UploadData :entity-set="entitySet" @uploaded="refresh" />
        <!-- <DownloadTemplate v-if="!hideTemplate" :entity-set="entitySet" /> -->
        <ResetData v-if="hideReset" :entity-set="entitySet" @refresh="refresh" />
      </div>
    </template>
    <!-- <template #body.append>
      <v-chip
        color="primary"
        v-for="(filter, index) in localFilters"
        :key="index"
      >
        {{ filter.text }}
        <v-icon @click="localFilters.splice(index, 1)">{{ mdiClose }}</v-icon>
      </v-chip>
    </template> -->
    <template #header="{ props }">
      <thead class="v-data-table-header mt-1">
        <tr>
          <th v-for="column in props.headers" :key="column.value" class="text-start sortable" :width="column.width">
            {{ column.text }}
          </th>
        </tr>
      </thead>
    </template>
    <template v-for="slot in slots" #[`item.${slot.name}`]="{ item, index }">
      <slot :name="slot.name" :item="item" :index="index"></slot>
    </template>
  </v-data-table>
</template>

<script>
import {
  mdiMagnify, mdiMenu, mdiClose, mdiRefresh,
} from '@mdi/js';
import { mapState } from 'vuex';
import { Client } from '@codehq/aurora-net';
import ODataQueryBuilder from './ODataQueryBuilder';
import ColumnDialog from './ColumnDialog.vue';
import FiltersDialog from './FiltersDialog.vue';
import DownloadTemplate from './DownloadTemplate.vue';
import DownloadData from './DownloadData.vue';
import UploadData from './UploadData.vue';
import ResetData from './ResetData.vue';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'ServerDataTable',
  components: {
    ColumnDialog,
    DownloadData,
    // eslint-disable-next-line vue/no-unused-components
    DownloadTemplate,
    FiltersDialog,
    ResetData,
    UploadData,
  },
  props: {
    allHeaders: {
      type: Array,
      required: true,
    },
    baseHeaders: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    hideTemplate: {
      type: Boolean,
      default: false,
    },
    hideReset: {
      type: Boolean,
      default: false,
    },
    entitySet: {
      type: String,
      required: true,
    },
    slots: {
      type: Array,
      default: () => [],
    },
    sortBy: {
      type: String,
      default: undefined,
    },
    sortDesc: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      mdiClose,
      mdiMagnify,
      mdiMenu,
      mdiRefresh,
      items: [],
      isLoading: false,
      metadata: [],
      localFilters: [],
      search: '',
      advSearch: {
        filter: 'IsDeleted eq false',
      },
      queryBuilder: new ODataQueryBuilder(`${baseUrl}/odata`, this.entitySet, this.localColumns),
      localColumns: this.headers ?? [],
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    client() {
      return new Client({
        baseUrl: process.env.VUE_APP_APIURL,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        apiClient: 'axios',
        timeout: 30000,
        headers: {
          organizationid: this.organization?.id,
        },
      });
    },
    filteredItems() {
      return this.items;
    },
  },
  watch: {
    headers: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localColumns = newValue;
      },
    },
    localColumns: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('update:headers', newValue);
      },
    },
    filters: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.advSearch.filter = newValue.map(f => `${f.query()}`).join(' and ');
        this.refresh();
      },
    },
    localFilters: {
      immediate: true,
      deep: true,
      handler(newValue) {
        const { filters } = this;
        const allFilters = [...filters, ...newValue];
        this.advSearch.filter = allFilters.map(f => `${f.query()}`).join(' and ');
        this.refresh();
      },
    },
  },
  async created() {
    const url = this.queryBuilder.getMetadataUrl();
    const { data } = await this.client.get(url);
    this.metadata = data.value;
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const { queryBuilder } = this;
      this.isLoading = true;
      try {
        const url = queryBuilder.buildUrl({ ...this.advSearch });
        const { data } = await this.client.get(url);
        this.items = data.value;
      } catch (error) {
        this.$log.error(error);
        if (error?.response?.status === 401) {
          this.$store.dispatch('auth/LOGOUT_auth');
          this.$router.push({ name: 'auth-login' });
          this.$log.warn('unauthorized');
        }
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error loading data');
      }
      this.isLoading = false;
    },
    rowClick(item) {
      this.$emit('row:click', item);
    },
  },
};
</script>
